/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/01/2021.
 */
import React, { useRef } from 'react'
import arrowSvg from '../assets/arrowBlack.svg'
import { useHover } from 'react-use-gesture'
import { gsap } from 'gsap'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const thankyou = (props) => {
  const arrowRef = useRef()
  const bind = useHover((hoverState) => {
    const to = hoverState.active ? 8 : 0
    gsap.to(arrowRef.current, 0.3, { x: to, ease: 'expo.out' })
  })

  // state will be defined if sent from contact form.
  const locState = props.location.state
  // console.log(' thanks > state = ', locState)

  return (
    <Layout>
      <div className="container-fluid h-100">
        <div className="row " style={{ minHeight: '80vh' }}>
          <div className="col-12 col-md-8 mx-auto d-flex flex-column justify-content-center align-items-center">
            <h2 className="text-center mb-4 text-primary">Thank you for staying with us!</h2>

            <p className="text-center">
              We'll reach out to you as soon as a new study appears in your area!
              <br />
              Kind regards, Studies&Me
            </p>

            <div {...bind()} className="mt-2 mt-md-2 text-center">
              <Link to={'/'}>
                <button
                  className="text-primary font-weight-bold"
                  style={{ border: 'none', background: 'none' }}
                  type="submit"
                >
                  <u>Back to frontpage</u>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default thankyou
